<template>
  <div>
    <!-- 存档按钮 -->
    <el-button
      type="info"
      size="small"
      @click="archive_baseline_dialog = true"
      v-limits-of-authority="'DATUM_LINE_CREATE_EDIT_COPY'"
      :disabled="limits_of_authority_disable('DATUM_LINE_CREATE_EDIT_COPY')"
    >
      <i class="iconfont icon-save1" style="margin-right: 5px"></i>
      {{ text }}
    </el-button>
    <!-- 存档弹窗 -->
    <el-dialog
      width="25%"
      top="25vh"
      custom-class="archive_baseline"
      :show-close="true"
      :title="title"
      :visible.sync="archive_baseline_dialog"
      :close-on-click-modal="false"
    >
      <!-- 存档表单 -->
      <el-form
        ref="archiveForm"
        label-width="120px"
        :rules="rules"
        :model="archive_form"
        label-position="left"
      >
        <!-- 版本 版本号 --必填 -->
        <el-form-item prop="archive_version">
          <template slot="label">
            <span>
              {{ archive_version }}
            </span>
          </template>
          <el-input v-model="archive_form.archive_version"></el-input>
        </el-form-item>
        <!-- 导出模板 --必填 -->
        <el-form-item prop="archive_tem">
          <template slot="label">
            <span>
              {{ archive_tem }}
            </span>
          </template>
          <el-select
            v-model="archive_form.tem"
            :placeholder="$t('placeholder.dropdown')"
            style="width: 100%"
            clearable
          >
            <el-option
              v-for="item in tem_options"
              :key="item.templateId"
              :label="item.name"
              :value="item.templateId"
            >
            </el-option>
          </el-select>
          <!-- <el-input v-model="archive_form.archive_version"></el-input> -->
        </el-form-item>
        <!-- 备注 --选填 -->
        <el-form-item prop="archive_remark">
          <template slot="label">
            <span>
              {{ archive_remark }}
            </span>
          </template>
          <el-input
            v-model="archive_form.archive_remark"
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 3 }"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button
          type="info"
          @click="
            archive_baseline_dialog = false;
            oncancel();
          "
        >
          {{ cancel }}
        </el-button>
        <el-button
          class="button-confirm"
          type="primary"
          @click="onsubmit()"
          :loading="loading"
        >
          {{ submit }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updateArchive } from "@/network/version/index.js";
import { get_templates } from "@/network/template/index.js";
import { limits_of_authority_disable } from "@/utils/limits";
import vmson from "@/utils/vmson";
export default {
  props: {
    versionId: {
      type: String,
    },
  },
  data() {
    var oncheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("baseline.archive.Error1")));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      // 按钮显示
      archive_baseline_dialog: false,
      text: this.$t("baseline.archive.title1"),
      archive_tem: this.$t("btn.export_tem"),
      title: this.$t("baseline.archive.title3"),
      archive_version: this.$t("baseline.archive.version"),
      archive_remark: this.$t("baseline.archive.remark"),
      //基线内容
      nodes_table: [],
      // 表单内容
      archive_form: {
        archive_version: "",
        archive_remark: "",
        tem: "",
      },
      // 校验规则
      rules: {
        archive_version: [
          {
            required: true,
            validator: oncheck,
            trigger: "blur",
          },
        ],
      },
      cancel: this.$t("baseline.archive.cancel"),
      submit: this.$t("baseline.archive.submit"),
      tem_options: [],
    };
  },
  created() {
    get_templates().then((res) => {
      this.tem_options = [
        ...res.filter((obj) => obj.exportTemplateType === "EXCEL"),
      ];
      this.archive_form.tem = "";
    });
  },
  methods: {
    limits_of_authority_disable,
    // 提交
    onsubmit() {
      //判断基线内容是否存在，基线内容不为空时才可以存档
      if (this.nodes_table.length != 0) {
        this.$refs["archiveForm"].validate((vaild) => {
          if (vaild) {
            let data = {
              archiveName: this.archive_form.archive_version,
              remark: this.archive_form.archive_remark,
              exportTemplateId: this.archive_form.tem,
            };
            try {
              this.loading = true;
              updateArchive(this.get_pid(), this.versionId, data)
                .then((e) => {
                  this.archive_baseline_dialog = false;
                  this.$message({
                    type: "success",
                    message: this.$t("baseline.message.archiveSuccess"),
                    duration: "5000",
                  });
                  this.loading = false;
                  // 清空表单
                  this.archive_form.tem = "";
                  this.onreset("archiveForm");
                })
                .catch((err) => {
                  console.log(err);
                  this.loading = false;
                });
            } catch (error) {
              console.log(error);
            }
          }
        });
      } else {
        this.$message({
          type: "warning",
          message: this.$t("baseline.message.versionArchiveFail"),
          duration: "5000",
        });
      }
    },
    // 取消
    oncancel() {
      // 清空表单
      this.onreset("archiveForm");
    },
    // 表单清空
    onreset(formName) {
      this.$refs[formName].resetFields();
    },
    //表单校验
  },
  mounted() {
    this.$nextTick(() => {
      // 从兄弟组件获取基线内容
      vmson.$on("nodes_list", (e) => {
        this.nodes_table = e;
      });
    });
  },
};
</script>
