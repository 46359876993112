<template>
  <div class="baseline_default">
    <div class="pic">
      <img src="@/assets/img/nothing.png" alt="" />
    </div>
    <div class="tip">{{ $t('placeholder.table1') }}</div>
    <!-- 基线新建按钮 -->
    <baseline-select
      :isDataLoaded = "isDataLoaded"
      :showDialog="showDialog"
      v-if="type == 'baseline'"
      @change_baseline="change_baseline"
      @dialog-closed="handleDialogClosed"
    ></baseline-select>
    <!-- 基线新建按钮新增 -->
    <div style="display: inline-block">
        <span v-limits-of-authority="'DATUM_LINE_CREATE_EDIT_COPY'">
            <el-button
            v-if="type == 'baseline'"
            :disabled="limits_of_authority_disable('DATUM_LINE_CREATE_EDIT_COPY')"
            type="primary"
            size="medium"
            @click="createBaseLineDialogShow"
            ><i class="iconfont icon-add"></i>&nbsp;&nbsp;{{$t('btn.newBtn')}}</el-button>
        </span>
     </div>
    <!-- 版本新建按钮 -->
      <div style="display: inline-block">
        <span v-limits-of-authority="'VERSION_CREATE_EDIT'">
            <el-button
            v-if="type == 'version'"
            :disabled="limits_of_authority_disable('VERSION_CREATE_EDIT')"
            type="primary"
            size="medium"
            @click="createVersionDialogShow"
            ><i class="iconfont icon-add"></i>&nbsp;&nbsp;{{$t('btn.newBtn')}}</el-button>
        </span>
     </div>
      <div style="display: inline-block">
           <span v-limits-of-authority="'TEST_PLAN_CREATE_EDIT'">
            <el-button
              v-if="type == 'testPlan'"
              :disabled="limits_of_authority_disable('TEST_PLAN_CREATE_EDIT')"
              type="primary"
              size="medium"
              @click="createTestDialogShow"
              ><i class="iconfont icon-add"></i>&nbsp;&nbsp;{{$t('btn.newBtn')}}</el-button
            >
            </span>
       </div>
  </div>
</template>

<script>
import BaselineSelect from "@/components/release/components/BaselineSelect"
import { get_data } from "@/network/baseline/index"
import { limits_of_authority_disable } from '@/utils/limits'
export default {
  name: "BaseLine",
  inject: ["reload"],
  components: {
    BaselineSelect,
  },
  props: {
    type: {
      type: String,
      default: "baseline",
    },
    dataLoaded:{
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      baseline_info: {},
      select_data: {
        baselineId: "",
        name: "",
        status: "",
      },
      is_edit: false,
      // 添加基线弹窗
      add_baseline_dialog: false,
      add_baseline_form: {
        name: "",
        desc: "",
      },
      add_baseline_rules: {},
      //复制基线弹窗
      copy_baseline_dialog: false,
      copy_name: "",
      isDataLoaded: false,
      showDialog: false,
    }
  },
  watch:{
    'dataLoaded':{
      handler(newVal,oldVal){
        if (newVal) {
          this.isDataLoaded = true
        }
      },
      immediate:true
    }
  },
  computed: {
    baselineId () {
      return this.$store.getters.baselineId
    },
  },
  methods: {
    limits_of_authority_disable,
    get_baseline_data () {
      get_data(this.get_pid(), this.baselineId).then((res) => {
        this.baseline_info = res
        Object.keys(this.select_data).forEach((item) => {
          this.select_data[item] = res[item]
        })
      })
    },

    add_baseline () { },
    copy_baseline () { },
    change_baseline (baselineId, status) {
      // 发送请求,重新渲染页面
      this.$store.commit("SET_BASELINE_ID", baselineId)
      this.$store.commit("SET_BASELINE_STATUS", status)
      this.reload()
      this.$router.push({
        name: "baseline",
        params: { cur_id: this.baselineId },
      });
    },
    createBaseLineDialogShow() {
       this.showDialog = true
    },
    createVersionDialogShow () {
      this.$emit('createClick')
    },
    createTestDialogShow () {
      this.$emit('createClick')
    },
    handleDialogClosed(info) {
      this.showDialog=info
    }
  },
};
</script>

<style lang="scss" scoped>
.baseline_default {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .tip {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .pic svg {
    font-size: 550px;
  }
}
</style>
