<template>
  <div>
    <!-- 版本存档查看 -->
    <div
      @click="
        archive_baseline_view_dialog = true;
        currentPage = 1;
        dialog_current_change();
      "
    >
      <i class="iconfont icon-save-list"></i>
    </div>
    <!-- 存档弹窗 -->
    <el-dialog
      :visible.sync="archive_baseline_view_dialog"
      custom-class="archive_baseline_view"
      :title="title"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <el-table
        :data="archive_baseline_form"
        style="width: 100%"
        :header-cell-style="{
          color: '#383838',
          'font-weight': '700',
        }"
        :cell-style="{
          color: '#636363',
          'font-weight': '700',
        }"
      >
        <!-- 基线版本号 -->
        <el-table-column prop="archiveName" :label="archive_version">
          <template slot-scope="scope">
            <el-link
              v-limits-of-authority="'NODE_EXPORT'"
              :href="scope.row.archiveLink"
              style="font-weight: 700; color: #409eff"
            >
              {{ scope.row.archiveName }}
            </el-link>
          </template>
        </el-table-column>
        <!-- 备注 -->
        <el-table-column
          prop="remark"
          :label="archive_remark"
          width="200"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <!-- show-overflow-tooltip="true" 内容过长提示 -->
        <!-- 存档人 -->
        <el-table-column prop="nickname" :label="archive_user">
        </el-table-column>
        <!-- 存档时间 -->
        <el-table-column prop="createdAt" :label="archive_time">
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div style="text-align: center; margin-top: 10px; margin-bottom: 24px">
        <el-pagination
          background
          layout="prev, pager, next, total"
          :total="totalElements"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="dialog_current_change"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { limits_of_authority_disable } from "@/utils/limits";
import { get_Version_archive } from "@/network/version/index.js";
import { mapActions } from "vuex";
export default {
  name: "BaseArchive",
  props: {
    versionId: {
      type: String,
    },
  },
  data() {
    return {
      title: this.$t("baseline.archive.title1"), // 弹框标题
      archive_baseline_view_dialog: false, //对话框显示
      // 表头
      archive_version: this.$t("baseline.archive.version"),
      archive_remark: this.$t("baseline.archive.remark"),
      archive_user: this.$t("baseline.archive.user"),
      archive_time: this.$t("baseline.archive.time"),
      // 存档数据池
      archive_baseline_form: [],
      currentPage: 1, //当前页码
      pageSize: 5, //指定展示多少条
      totalElements: null, //总条目数
      archiveLink: null, //存档链接
    };
  },
  methods: {
    //权限管理
    limits_of_authority_disable,
    // 获取基线id
    ...mapActions(["setBaseLine"]),
    // 存档人匹配 查找nickname
    get_archiveName(id) {
      let user_list = this.$store.getters.user_list;
      return user_list[id.toString()].nickname;
    },
    //获取存档记录
    async get_archives_list(currentPage, pageSize) {
      // 保证参数有效
      if (currentPage === undefined || pageSize == undefined) {
        currentPage == this.currentPage;
        pageSize == this.pageSize;
      }
      try {
        let res = await get_Version_archive(
          this.get_pid(),
          this.versionId,
          currentPage,
          pageSize
        );
        this.archive_baseline_form = res.content;
        // 获取总条目
        this.totalElements = res.totalElements;
        this.archive_baseline_form.forEach((item) => {
          // 用户id匹配vickname
          let nickname = this.get_archiveName(item.createdBy);
          item.nickname = nickname;
          // 存档链接拼接
          item.archiveLink = item.objectName;
        });
      } catch (error) {
        console.log(error);
      }
    },
    // 页码改变触发函数
    dialog_current_change(currentPage) {
      this.currentPage = currentPage;
      // 页码初始化 兜底
      if (!this.currentPage) {
        this.currentPage = 1;
      }
      // 后端分页，页码改变发请求
      let pageNum = this.currentPage - 1; //当前页码-1 ，从0开始
      let pageSize = this.pageSize; //单页条目数量
      this.get_archives_list(pageNum, pageSize); //接收两个参数， 当前页码 & 单页条目数量
    },
  },
};
</script>
<style lang="scss">
.icon-save-list {
  // 图标单独样式 不继承按钮
  color: #174fa9;
  background-color: #eee;
  font-size: 25px;
  cursor: pointer;

  &:hover {
    color: #174fa9;
  }
}
</style>
