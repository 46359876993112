var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{on:{"click":function($event){_vm.archive_baseline_view_dialog = true;
      _vm.currentPage = 1;
      _vm.dialog_current_change();}}},[_c('i',{staticClass:"iconfont icon-save-list"})]),_c('el-dialog',{attrs:{"visible":_vm.archive_baseline_view_dialog,"custom-class":"archive_baseline_view","title":_vm.title,"close-on-click-modal":false,"show-close":true},on:{"update:visible":function($event){_vm.archive_baseline_view_dialog=$event}}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.archive_baseline_form,"header-cell-style":{
        color: '#383838',
        'font-weight': '700',
      },"cell-style":{
        color: '#636363',
        'font-weight': '700',
      }}},[_c('el-table-column',{attrs:{"prop":"archiveName","label":_vm.archive_version},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-link',{directives:[{name:"limits-of-authority",rawName:"v-limits-of-authority",value:('NODE_EXPORT'),expression:"'NODE_EXPORT'"}],staticStyle:{"font-weight":"700","color":"#409eff"},attrs:{"href":scope.row.archiveLink}},[_vm._v(" "+_vm._s(scope.row.archiveName)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"remark","label":_vm.archive_remark,"width":"200","show-overflow-tooltip":true}}),_c('el-table-column',{attrs:{"prop":"nickname","label":_vm.archive_user}}),_c('el-table-column',{attrs:{"prop":"createdAt","label":_vm.archive_time}})],1),_c('div',{staticStyle:{"text-align":"center","margin-top":"10px","margin-bottom":"24px"}},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next, total","total":_vm.totalElements,"page-size":_vm.pageSize,"current-page":_vm.currentPage},on:{"current-change":_vm.dialog_current_change}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }