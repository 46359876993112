<template>
  <div v-loading="loading" style="height: 100%; overflow: auto">
    <div
      v-if="cur_version_id"
      style="height: 100%; display: flex; flex-direction: column"
    >
      <div class="header">
        <div v-show="is_display" class="header__version-select">
          <el-select
            ref="refId"
            v-model="cur_version_id"
            size="small"
            filterable
            popper-class="select_own"
          >
            <el-option
              :key="'add_new'"
              class="version-add-option"
              :value="cur_version_id"
            >
              <div
                v-limits-of-authority="'VERSION_CREATE_EDIT'"
                class="version-add"
                style="display: flex; justify-content: center"
                @click="open_new_version_dialog"
              >
                <i class="iconfont icon-add"></i>
              </div>
            </el-option>
            <el-option
              v-for="item in version_options_display"
              :key="item.versionId"
              :label="item.name"
              :value="item.versionId"
            >
            </el-option>
          </el-select>
        </div>
        <div
          v-show="is_display && !is_release && cur_version_id"
          class="release"
        >
          <span
            v-limits-of-authority="'VERSION_RELEASE'"
            style="display: inline-block"
          >
            <!-- 发布图标 -->
            <el-button
              type="primary"
              size="small"
              :disabled="limits_of_authority_disable('VERSION_RELEASE')"
              @click="release_version"
            >
              <i
                class="iconfont icon-icon-fabu-version"
                style="
                  color: rgba(255, 255, 255);
                  font-size: 21px;
                  line-height: 10px;
                "
              ></i>
              {{ $t("version.publishBtn") }}
            </el-button>
          </span>
          <span
            style="display: inline-block; margin-left: 10px"
            @click="toPubK"
          >
            <i
              class="iconfont icon-a-fenzu6"
              style="
                cursor: pointer;
                font-size: 14px;
                font-weight: 300;
                line-height: 28px;
              "
            ></i>
          </span>
        </div>
        <div class="header__version-edit">
          <el-input
            v-show="is_edit_nonrelease"
            v-model="display_data.name"
            size="small"
            @blur="edit_version"
          >
          </el-input>
          <span
            v-show="is_edit_release"
            style="font-size: 16px; color: rgba(166, 166, 166, 1)"
            >{{ display_data.name }}</span
          >
        </div>
        <div class="header__btns">
          <!-- 查看存档按钮 -->
          <version-archive-viewbtn :versionId="cur_version_id">
          </version-archive-viewbtn>
          <!-- 版本存档按钮 -->
          <version-archivebtn :versionId="cur_version_id"> </version-archivebtn>
          <span v-limits-of-authority="'VERSION_CREATE_EDIT'">
            <el-button
              v-show="isExoprt && false"
              :disabled="limits_of_authority_disable('VERSION_CREATE_EDIT')"
              type="info"
              size="small"
              @click="export_excel"
            >
              <i
                class="iconfont icon-export_primary"
                style="margin-right: 5px"
              ></i
              >{{ $t("btn.exportBtn") }}
            </el-button>
            <el-button
              v-show="is_display && cur_version_id"
              :disabled="limits_of_authority_disable('VERSION_CREATE_EDIT')"
              type="primary"
              size="small"
              @click="edit_click"
              ><i
                class="iconfont icon-branch_edit"
                style="margin-right: 5px"
              ></i
              >{{ $t("btn.editBtn") }}</el-button
            >
          </span>
          <div class="header__btns-edit">
            <div v-show="is_auto_save && is_edit">
              <i class="iconfont icon-refresh" style="margin-right: 5px"> </i
              ><span
                style="
                  margin-right: 10px;
                  font-size: 12px;
                  color: rgba(166, 166, 166, 1);
                "
                >{{ $t("baseline.topBar.autoSave") }}</span
              >
            </div>
            <span style="margin-right: 6px">
              <span v-limits-of-authority="'VERSION_DELETE'">
                <el-button
                  v-show="is_edit"
                  :disabled="limits_of_authority_disable('VERSION_DELETE')"
                  type="info"
                  size="small"
                  @click="delete_version"
                  ><i class="iconfont icon-delete" style="margin-right: 5px"></i
                  >{{ $t("btn.deleteBtn") }}</el-button
                >
              </span>
            </span>
            <el-button
              v-if="is_edit"
              type="info"
              size="small"
              @click="exit_edit_click"
              ><i class="iconfont icon-exit" style="margin-right: 5px"></i>
              {{ $t("btn.exitEditBtn") }}</el-button
            >
          </div>
        </div>
      </div>
      <div
        @mouseenter="summaryOnmouserEnter"
        @mouseleave="summaryOnmouserLeave"
      >
        <el-collapse
          v-model="activeNames"
          class="scroll_wrap"
          @change="handleChange"
        >
          <el-collapse-item class="collapse_item" name="1">
            <div slot="title" class="collapse-title">
              {{ $t(`nodeDetail.basicInfo.title`) }}
            </div>
            <div class="version-info">
              <div class="release-time-and-person">
                <el-row style="width: 100%">
                  <el-col :span="8">
                    <div class="release-time">
                      <span
                        class="title"
                        :style="is_edit_nonrelease ? 'width:120px;' : ''"
                        >{{ $t("version.basicInfo.publishTime") }}:</span
                      >
                      <span v-show="is_display || is_edit_release">{{
                        display_data.releaseDateTime
                      }}</span>
                      <el-date-picker
                        v-show="is_edit_nonrelease"
                        v-model="display_data.releaseDateTime"
                        type="datetime"
                        :placeholder="$t('version.basicInfo.placeholder')"
                        size="small"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="picker_options"
                        @change="edit_version"
                      ></el-date-picker>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div v-if="is_release" class="release-time">
                      <span
                        class="title"
                        :style="is_edit_nonrelease ? 'width:120px;' : ''"
                        >{{ $t("version.basicInfo.actualTime") }}:</span
                      >
                      <span>{{ display_data.releaseActualDateTime }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="release-person">
                      <span style="font-weight: bold; min-width: 120px"
                        >{{ $t("version.basicInfo.responsibility") }}:</span
                      >
                      <span v-show="is_display || is_edit_release">{{
                        display_data.responsible_name
                      }}</span>
                      <el-select
                        v-show="is_edit_nonrelease"
                        v-model="display_data.responsible"
                        size="small"
                        filterable
                        @change="edit_version"
                      >
                        <el-option
                          v-for="item in userList"
                          v-show="!item.deleted"
                          :key="item.accountId"
                          :label="item.nickname"
                          :value="item.accountId"
                        ></el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col v-if="!is_release" :span="8">
                    <div class="notice-person">
                      <span
                        class="title"
                        style="font-weight: bold; min-width: 120px"
                        >{{ $t("version.basicInfo.notice") }}:</span
                      >
                      <span v-show="is_display || is_edit_release">
                        <span>
                          <span
                            v-for="(item, index) in get_show_person()"
                            :key="index"
                            >{{ item }}，</span
                          ></span
                        >
                      </span>
                      <el-select
                        v-show="is_edit_nonrelease"
                        v-model="display_data.notice_person"
                        multiple
                        :placeholder="$t('placeholder.dropdown')"
                        size="small"
                        filterable
                        @visible-change="save_notice_person"
                        @remove-tag="save_notice_person_2"
                      >
                        <el-option-group
                          v-for="(group, index) in groupandUser"
                          :key="group.label"
                          :label="group.label"
                        >
                          <el-option
                            v-for="user in group.options"
                            :key="user.value"
                            :label="user.label"
                            :value="user.value + index"
                          >
                          </el-option>
                        </el-option-group>
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div v-if="is_release" class="release-time-and-person">
                <el-row style="width: 100%">
                  <el-col :span="8">
                    <div class="notice-person">
                      <span
                        class="title"
                        style="font-weight: bold; min-width: 120px"
                        >{{ $t("version.basicInfo.notice") }}:</span
                      >
                      <span v-show="is_display || is_edit_release">
                        <span>
                          <span
                            v-for="(item, index) in get_show_person()"
                            :key="index"
                            >{{ item }}，</span
                          ></span
                        >
                      </span>
                      <el-select
                        v-show="is_edit_nonrelease"
                        v-model="display_data.notice_person"
                        multiple
                        :placeholder="$t('placeholder.dropdown')"
                        size="small"
                        filterable
                        @visible-change="save_notice_person"
                        @remove-tag="save_notice_person_2"
                      >
                        <el-option-group
                          v-for="(group, index) in groupandUser"
                          :key="group.label"
                          :label="group.label"
                        >
                          <el-option
                            v-for="user in group.options"
                            :key="user.value"
                            :label="user.label"
                            :value="user.value + index"
                          >
                          </el-option>
                        </el-option-group>
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="baseline-and-test">
                <el-row style="width: 100%">
                  <el-col :span="8">
                    <div class="baseline">
                      <span
                        class="title"
                        :style="is_edit_nonrelease ? 'width:120px;' : ''"
                        >{{ $t("version.basicInfo.baseline") }}:</span
                      >
                      <span
                        v-show="is_display || is_edit_release"
                        class="bgc-blue"
                      >
                        <span>
                          <span
                            v-for="(item, index) in baselineName_list"
                            :key="index"
                            @click="jump_to_baseline(item)"
                            >{{ item.name
                            }}<i v-if="index !== baselineName_list.length - 1"
                              >，</i
                            ></span
                          ></span
                        >
                      </span>
                      <el-select
                        v-show="is_edit_nonrelease"
                        v-model="display_data.baseline"
                        multiple
                        :placeholder="$t('placeholder.dropdown')"
                        size="small"
                        filterable
                        @focus="baseline_select_focus"
                        @change="baseline_select_change"
                      >
                        <el-option
                          v-for="item in display_data.baseline_options"
                          :key="item.baselineId"
                          :label="item.name"
                          :value="item.baselineId"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="test_label">
                      <span style="font-weight: bold; min-width: 120px"
                        >{{ $t("version.basicInfo.testPlan") }}:</span
                      >
                      <span
                        v-show="is_display || is_edit_release"
                        class="bgc-blue"
                      >
                        <span
                          v-for="(item, index) in testPlanName_list"
                          :key="index"
                          @click="jump_to_test(item)"
                          >{{ item.name
                          }}<i v-if="index !== testPlanName_list.length - 1"
                            >，</i
                          ></span
                        >
                      </span>
                      <el-select
                        v-show="is_edit_nonrelease"
                        v-model="display_data.testPlan"
                        multiple
                        filterable
                        :placeholder="$t('placeholder.dropdown')"
                        size="small"
                        @focus="testPlan_select_focus"
                        @change="testPlan_select_change"
                      >
                        <el-option
                          v-for="item in display_data.testPlan_options"
                          :key="item.testPlanId"
                          :label="item.name"
                          :value="item.testPlanId"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col v-if="project_type === 'SCRUM'" :span="8">
                    <div class="sprint">
                      <span
                        style="
                          font-weight: bold;
                          min-width: 120px;
                          display: inline-block;
                        "
                        >{{ $t("version.basicInfo.sprint") }}:</span
                      >
                      <span
                        v-show="is_display || is_edit_release"
                        class="bgc-blue"
                      >
                        <span
                          v-for="(item, index) in sprintName_list"
                          :key="index"
                          @click="jump_to_sprint(item)"
                          >{{ item.name
                          }}<i v-if="index !== sprintName_list.length - 1"
                            >，</i
                          ></span
                        >
                      </span>
                      <el-select
                        v-show="is_edit_nonrelease"
                        v-model="display_data.sprint"
                        multiple
                        :placeholder="$t('placeholder.dropdown')"
                        size="small"
                        filterable
                        @focus="sprint_select_focus"
                        @change="sprint_select_change"
                      >
                        <el-option
                          v-for="item in display_data.sprint_options"
                          :key="item.sprintId"
                          :label="item.name"
                          :value="item.sprintId"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="version-release-plan">
                <span
                  class="title"
                  :style="
                    is_edit_nonrelease ? 'width:120px;' : 'min-width:120px;'
                  "
                  >{{ $t("version.basicInfo.goal") }}:</span
                >
                <el-scrollbar style="max-height: 80px; flex: 1">
                  <span
                    v-show="is_display || is_edit_release"
                    class="description"
                    v-html="newReleaseTarget"
                  ></span>
                </el-scrollbar>
                <el-input
                  v-show="is_edit_nonrelease"
                  v-model="display_data.releaseTarget"
                  type="textarea"
                  :autosize="{ maxRows: 4 }"
                  size="small"
                  @blur="edit_version"
                ></el-input>
              </div>
              <div class="remark">
                <span
                  class="title"
                  :style="is_edit_nonrelease ? 'width:120px; ' : 'width:100px'"
                  >{{ $t("version.basicInfo.note") }}:</span
                >
                <el-scrollbar style="max-height: 80px; flex: 1">
                  <span
                    v-show="is_display"
                    class="description"
                    v-html="newRemark"
                  ></span>
                </el-scrollbar>
                <el-input
                  v-show="is_edit"
                  v-model="display_data.remark"
                  size="small"
                  type="textarea"
                  :autosize="{ maxRows: 4 }"
                  @blur="edit_version"
                >
                </el-input>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="aboveTable">
        <div
          v-if="num_obj.done_num + num_obj.not_sum > 0"
          class="finish-performance"
        >
          <span style="margin-right: 4px">{{ $t("test.status") }} &nbsp;</span>
          <div
            style="
              height: 15px;
              border-radius: 20px;
              background-color: rgba(255, 87, 51, 0.3);
              width: 200px;
              display: flex;
              align-items: center;
            "
          >
            <div
              v-if="num_obj.done_num > 0"
              :style="
                'color:rgb(0 167 11);height:16px;border-radius: 20px;display: flex;justify-content: center;align-items: center;background-color:rgb(188,232,208);width:' +
                (num_obj.done_num / (num_obj.not_sum + num_obj.done_num)) *
                  100 +
                '%'
              "
            >
              {{ num_obj.done_num }}
            </div>
            <div
              v-if="num_obj.not_sum > 0"
              :style="
                'color:rgba(255, 87, 51, 1);height:16px;display: flex;justify-content: center;align-items: center;width:' +
                (100 -
                  (num_obj.done_num / (num_obj.not_sum + num_obj.done_num)) *
                    100) +
                '%'
              "
            >
              {{ num_obj.not_sum }}
            </div>
          </div>
        </div>
        <!-- 添加新节点 -->
        <div v-show="is_edit_nonrelease" class="add_nodes">
          <!-- <span class="title">{{ $t("version.addNode.title") }}</span> -->
          <el-select
            v-model="display_data.add_nodes"
            multiple
            size="small"
            filterable
            collapse-tags
            remote
            :remote-method="get_add_nodes_options"
            :loading="addNodesOptionsLoading"
            :popper-append-to-body="false"
            :placeholder="$t('version.addNode.placeholder')"
            :reserve-keyword="true"
            popper-class="select-icon-height-auto-class"
          >
            <el-option
              v-for="item in display_data.add_nodes_options"
              :key="item.nodeKey"
              :label="`${item.nodeKey} ${item.topic}`"
              :value="item.nodeKey"
              style="text-align: left"
            >
              <select-icon :item="item"> </select-icon>
            </el-option>
          </el-select>
          <span>
            <el-button
              v-limits-of-authority="'NODE_EDIT'"
              :disabled="limits_of_authority_disable('NODE_EDIT')"
              type="primary"
              size="small"
              :loading="add_nodes_loading"
              @click="add_nodes"
              >{{ $t("btn.addBtn") }}</el-button
            >
          </span>
        </div>
      </div>
      <div class="version-nodes" style="flex: 1; overflow: hidden">
        <el-table
          :data="current_nodes_table"
          :header-cell-style="{
            color: '#383838',
            fontWeight: 'bold',
            fontSize: '14px',
            backgroundColor: 'transparent',
          }"
          :cell-style="set_cell_style"
          height="100%"
        >
          <el-table-column
            label="Key"
            min-width="180"
            align="left"
            header-align="left"
          >
            <template slot-scope="scope">
              <span
                v-limits-of-authority="'NODE_DETAIL_READ'"
                style="cursor: pointer; font-weight: bold"
                @click="route_to_node_details(scope.row)"
                >{{ scope.row.key }}</span
              >
              <div style="display: inline-block">
                <div
                  v-limits-of-authority="'FILE_READ'"
                  style="display: inline-block"
                >
                  <i
                    style="
                      font-size: 12px;
                      margin-left: 16px;
                      cursor: pointer;
                      color: rgb(48, 100, 143);
                    "
                    class="iconfont icon-node_link"
                    @click="route_to_node(scope.row)"
                  ></i>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="topic"
            :label="$t('version.nodeTable.title')"
            min-width="300"
            align="left"
            show-overflow-tooltip
            header-align="left"
          >
            <template slot-scope="{ row }">
              <span style="cursor: pointer" @click="show_drawer(row)">{{
                row.topic
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('version.nodeTable.status')"
            min-width="160"
            align="left"
            header-align="left"
          >
            <template slot-scope="scope">
              <!-- <status-tag
                  v-model="scope.row.status"
                  :style_catogry="'plain'"
                  :status_type="scope.row.statusCategory"
                  :size="'small'"
              >
              </status-tag> -->
              <status-select
                v-model="scope.row.status"
                :node-key="scope.row.key"
                :status_type="scope.row.statusCategory"
                :get_version_detail="get_version_detail"
              ></status-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="assigneeName"
            :label="$t('version.nodeTable.responsibility')"
            min-width="180"
            align="left"
            header-align="left"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="createdBy"
            :label="$t('version.nodeTable.operator')"
            min-width="180"
            align="left"
            header-align="left"
          >
            <template slot-scope="scope">
              <span class="topic">{{
                matchUserNickname(scope.row.createdBy)
              }}</span></template
            >
          </el-table-column>
          <el-table-column
            prop="changeTime"
            :label="$t('version.nodeTable.operationTime')"
            min-width="180"
            align="left"
            header-align="left"
          ></el-table-column>
          <el-table-column
            v-if="is_edit_nonrelease"
            min-width="80"
            align="left"
            header-align="left"
          >
            <template slot-scope="scope">
              <i
                v-limits-of-authority="'NODE_EDIT'"
                v-show="
                  !(delete_nodes_loading && scope.row.key == delete_nodes_key)
                "
                style="cursor: pointer; color: #f56c6c"
                class="iconfont icon-baseline-delete"
                @click="delete_nodes(scope.row.key, scope.row)"
              ></i>
              <i
                v-show="
                  delete_nodes_loading && scope.row.key == delete_nodes_key
                "
                class="el-icon-loading"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 20px">
        <el-pagination
          :page-size="pageSize"
          :pager-count="pageCount"
          :current-page.sync="pageCurrent"
          class="header-pagination"
          style="text-align: end"
          :background="false"
          small
          layout="prev, pager, next"
          :total="nodes_list.length"
        >
        </el-pagination>
      </div>
    </div>
    <release-default-vue
      v-if="!cur_version_id"
      :type="'version'"
      @createClick="open_new_version_dialog"
    >
    </release-default-vue>
    <!-- 新建版本弹窗 -->
    <el-dialog
      :visible.sync="is_dialog_visible"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title" class="dialog-title">
        <span class="title-text">{{ $t("version.addVersionForm.title") }}</span>
      </div>
      <div class="dialog-content">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="140px"
          label-position="left"
        >
          <el-form-item prop="name">
            <template slot="label">
              <span>{{ $t("version.addVersionForm.name") }}</span>
            </template>
            <el-input
              v-model="ruleForm.name"
              size="small"
              :maxlength="255"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('version.addVersionForm.baseline')"
            prop="baseline"
          >
            <el-select
              v-model="ruleForm.baseline"
              multiple
              filterable
              :placeholder="$t('placeholder.dropdown')"
              size="small"
              style="width: 100%"
            >
              <el-option
                v-for="item in ruleForm.baseline_options"
                :key="item.baselineId"
                :label="item.name"
                :value="item.baselineId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('version.addVersionForm.testPlan')"
            prop="testPlan"
          >
            <el-select
              v-model="ruleForm.testPlan"
              multiple
              filterable
              :placeholder="$t('placeholder.dropdown')"
              size="small"
              style="width: 100%"
            >
              <el-option
                v-for="item in ruleForm.testPlan_options"
                :key="item.testPlanId"
                :label="item.name"
                :value="item.testPlanId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('version.addVersionForm.goal')"
            prop="releaseTarget"
          >
            <el-input
              v-model="ruleForm.releaseTarget"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="info" @click="is_dialog_visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="create_version_click">{{
          $t("btn.addBtn")
        }}</el-button>
      </div>
    </el-dialog>

    <!-- 未完成节点发布警告弹窗 -->
    <el-dialog
      :title="$t('review.tip11')"
      :visible="dialogVisible"
      width="25%"
      height="8vh"
      :close-on-click-modal="false"
      :show-close="true"
      :before-close="handleClose"
    >
      <div style="display: flex; padding-bottom: 20px">
        <i
          style="font-size: 20px; color: rgb(255, 195, 0)"
          class="iconfont icon-jingshi-tianchong"
        ></i>
        <span style="float: left; text-align: start; padding-left: 10px">{{
          $t("release.IncompleteNodesCurrentVersion")
        }}</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="release_version_api">{{
          $t("review.tip14")
        }}</el-button>
        <el-button @click="dialogVisible = false">{{
          $t("review.tip13")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('file.table.options.dialog.title')"
      width="25%"
      :visible="dialogExport"
      :close-on-click-modal="false"
      :show-close="true"
      :before-close="handleCloseExp"
      custom-class="excel_export_dialog"
    >
      <el-checkbox v-model="all_fields_item" @change="judge_all">{{
        $t("file.table.options.dialog.box1")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled> Key </el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box4")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box11")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("file.table.options.dialog.box10")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("nodeDetail.activity.code.commit.operator")
      }}</el-checkbox>
      <el-checkbox v-model="must_check" disabled>{{
        $t("nodeDetail.activity.history.time")
      }}</el-checkbox>
      <el-checkbox-group
        v-model="submit_list"
        text-color=" rgba(66, 159, 255, 1)"
      >
        <el-checkbox
          v-for="(item, index) in checkList"
          :key="index"
          :label="item.fieldId"
          >{{ item.label }}</el-checkbox
        >
      </el-checkbox-group>

      <span slot="footer">
        <el-button @click="dialogExport = false">{{
          $t("file.table.options.dialog.box5")
        }}</el-button>
        <el-button
          v-loading="excelExportLoading"
          type="primary"
          @click="submit_Excel"
          >{{ $t("file.table.options.dialog.box6") }}</el-button
        >
      </span>
    </el-dialog>
    <NodeDrawer
      :drawer.sync="drawer"
      :current_node="current_node"
      :file_icon_name="file_icon_name"
      :drawer_loading="drawer_loading"
      :file_icon="file_icon"
      :members-show="membersShow"
      :members="members"
      :show-test-plan="showTestPlan"
      @close="before_close_drawer"
      @loading-finished="drawer_loading = false"
      @update_description_content="update_description_content"
      @update_current_node="update_current_node"
    />
  </div>
</template>

<script>
import { limits_of_authority_disable } from "@/utils/limits";
import selectIcon from "@/components/selectIcon";
import {
  check_version_name,
  create_version,
  get_all_versions,
  get_version_detail,
  get_version_nodes,
  delete_version,
  edit_version,
  add_nodes,
  delete_nodes,
  add_sprints,
  delete_sprints,
  add_baselines,
  delete_baselines,
  add_testPlans,
  delete_testPlans,
  release_version,
  export_excel_version,
} from "@/network/version/index.js";
import { getSystemFields, all_fields_get } from "@/network/fileType/index.js";
import { get_nodes_by_fuzzykey } from "@/network/node/index.js";
import { get_node_use_node_key } from "@/network/node/index.js";
import { getFile } from "@/network/home/index.js";
import Vue from "vue";
import ReleaseDefaultVue from "@/components/release";
import StatusSelect from "@/components/statusSelect";
import { mapActions, mapGetters } from "vuex";
import NodeDrawer from "@/components/nodeDrawer";
import { inProgressBaselines } from "@/network/merge/index";
import { get_allProjectMember } from "@/network/node/index.js";
import { get_group_user_list } from "@/network/addGroup/index.js";
import { downloadLink } from "@/utils/download.js";
import VersionArchiveViewbtn from "./components/VersionArchiveViewbtn.vue";
import VersionArchivebtn from "./components/VersionArchivebtn.vue";
import vmson from "@/utils/vmson";

export default {
  name: "Version",
  components: {
    ReleaseDefaultVue,
    StatusSelect,
    NodeDrawer,
    selectIcon,
    VersionArchiveViewbtn,
    VersionArchivebtn,
  },
  data() {
    const version_name_only = (rule, value, callback) => {
      check_version_name(this.get_pid(), this.ruleForm.name).then((res) => {
        if (res == true) {
          callback(new Error(this.$t("version.message.addError")));
        } else {
          callback();
        }
      });
    };
    return {
      // 页面加载
      loading: false,
      // 当前项目成员
      // 当前版本
      cur_version_id: "",
      // 版本选择下拉框中的值
      version_options: [],
      // 版本选择下拉框中的值添加是否发布状态
      version_options_display: [],
      // 是否正在编辑
      is_edit: false,
      // 是否已发布
      is_release: false,
      // 展示数据
      display_data: {
        name: "",
        releaseDateTime: "",
        responsible: "",
        responsible_name: "",
        // baseline
        baseline: [],
        baseline_options: [],
        // 关联测试计划
        testPlan: [],
        testPlan_options: [],
        // 关联sprint
        sprint: [],
        sprint_options: [],
        releaseTarget: "",
        remark: "",
        versionNodeDetail: [],
        // 添加节点
        add_nodes: [],
        add_nodes_options: [],
        // 发布时通知
        notice_person: [],
      },
      notice_person_list: [],
      groupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      must_check: true,
      excelExportLoading: false,
      dialogExport: false,
      all_fields_item: false,
      excel_dialog_fields: {},
      systemFields_list: [],
      allFields_list: [],
      submit_list: [],
      checkList: [],
      isExoprt: true,
      // 新增版本对话框是否可见
      is_dialog_visible: false,
      // 新增版本对话框数据
      ruleForm: {
        name: "",
        baseline: [],
        baseline_options: [],
        testPlan: [],
        testPlan_options: [],
        releaseTarget: "",
      },
      // 新增版本对话框的规则
      rules: {
        name: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "blur",
          },
          { validator: version_name_only, trigger: "blur" },
        ],
      },
      newReleaseTarget: "",
      newRemark: "",
      // 节点模糊搜索loading
      addNodesOptionsLoading: false,
      // 添加节点loading
      add_nodes_loading: false,
      // 删除节点loading
      delete_nodes_loading: false,
      //当前正在删除的节点key
      delete_nodes_key: "",
      // 关联baseline更改前原值
      baseline_old_value: [],
      // 关联testPlan更改前原值
      testPlan_old_value: [],
      // 关联sprint更改前原值
      sprint_old_value: [],
      // 日期选择框选择选项
      picker_options: {
        disabledDate(time) {
          return time.getTime() <= Date.now();
        },
      },
      // 是否正在自动保存
      is_auto_save: false,
      // 未发布弹窗
      dialogVisible: false,
      drawer: false,
      drawer_loading: false,
      workflow_dialog_visible: false,
      input_name: "",
      is_show_name: true,
      current_node: {},
      file_icon_name: "",
      file_icon: "",
      activeNames: [],
      pageSize: 20,
      pageCount: 5,
      pageCurrent: 1,
      current_nodes_table: [],
    };
  },
  computed: {
    ...mapGetters([
      "project_type",
      "user_group_list",
      "status",
      "testPlan",
      "baseLine",
      "sprint",
      "fileType",
      "project_user_list",
    ]),
    userList() {
      return Object.values(this.project_user_list);
    },
    account_id() {
      return this.$store.getters.userAccountId;
    },
    responsible() {
      return this.display_data.responsible;
    },
    baselineName_list() {
      let result = [];
      this.display_data.baseline.forEach((id) => {
        result.push(
          this.display_data.baseline_options.find(
            (item) => item.baselineId == id
          )
        );
      });
      return result;
    },
    testPlanName_list() {
      let result = [];
      this.display_data.testPlan.forEach((id) => {
        result.push(
          this.display_data.testPlan_options.find(
            (item) => item.testPlanId == id
          )
        );
      });
      return result;
    },
    sprintName_list() {
      let result = [];
      this.display_data.sprint.forEach((id) => {
        result.push(
          this.display_data.sprint_options.find((item) => item.sprintId == id)
        );
      });
      return result;
    },
    // 页面是什么状态
    is_display() {
      return !this.is_edit;
    },
    is_edit_nonrelease() {
      return this.is_edit && !this.is_release;
    },
    is_edit_release() {
      return this.is_edit && this.is_release;
    },
    num_obj() {
      let not_yet_num = this.nodes_list.filter(
        (item) => item.statusCategory !== "DONE"
      );
      const not_sum = not_yet_num.length;
      const done_num = this.nodes_list.length - not_sum;
      return {
        not_sum: not_sum,
        done_num: done_num,
      };
    },
    nodes_list() {
      let added = this.display_data.versionNodeDetail.added || [];
      let referenced = this.display_data.versionNodeDetail.referenced || [];
      let result = [...added];
      referenced.forEach((item) => {
        if (item.nodeList) {
          item.nodeList.forEach((node) => {
            node.referenced = true;
          });
          result = [...result, ...item.nodeList];
        }
      });
      // result 结果去重
      let map = new Map();
      result.forEach((item) => {
        if (!map.has(item.key)) {
          map.set(item.key, item);
        }
      });
      result = [...map.values()];
      result.forEach((item) => {
        let changeTime = new Date(item.createdAt);
        changeTime = `${changeTime.getFullYear()}-${
          changeTime.getMonth() + 1 > 9
            ? changeTime.getMonth() + 1
            : "0" + (changeTime.getMonth() + 1)
        }-${
          changeTime.getDate() > 9
            ? changeTime.getDate()
            : "0" + changeTime.getDate()
        }
        ${
          changeTime.getHours() > 9
            ? changeTime.getHours()
            : "0" + changeTime.getHours()
        }:${
          changeTime.getMinutes() > 9
            ? changeTime.getMinutes()
            : "0" + changeTime.getMinutes()
        }:${
          changeTime.getSeconds() > 9
            ? changeTime.getSeconds()
            : "0" + changeTime.getSeconds()
        }`;
        Vue.set(item, "changeTime", changeTime);
      });
      result.forEach((item) => {
        item.assigneeName = this.matchUserNickname(item.assignee, "");
        if (this.status[item.status]) {
          item.statusCategory = this.status[item.status].statusCategory;
          item.status = this.status[item.status].name;
        }
      });
      vmson.$emit("nodes_list", result);
      return result;
    },
    showTestPlan() {
      if (this.current_node && this.current_node.fileTypeId && this.fileType) {
        return this.fileType[this.current_node.fileTypeId]
          ? this.fileType[this.current_node.fileTypeId].testCase
          : false;
      }
      return false;
    },
    members() {
      return Object.values(this.project_user_list);
    },
    membersShow() {
      return this.members.length ? true : false;
    },
  },
  watch: {
    cur_version_id: function () {
      this.display_data.name = this.version_options.find((item) => {
        return item.versionId === this.cur_version_id;
      }).name;
      // 存入sessionStorage
      sessionStorage.setItem("version_id", this.cur_version_id);
      this.get_version_detail();
      this.$router.push({
        name: "version",
        params: { cur_id: this.cur_version_id },
      });
    },
    responsible: function () {
      this.display_data.responsible_name = this.matchUserNickname(
        this.responsible,
        ""
      );
    },
    version_options: {
      handler: function () {
        this.version_options_display = [];
        this.version_options.forEach((item) => {
          let new_item = { ...item };
          if (new_item.status) {
            new_item.name +=
              "  (" + this.$t("canvas.testPlan.status.published") + ")";
          } else {
            new_item.name +=
              "  (" + this.$t("canvas.testPlan.status.notPublished") + ")";
          }
          this.version_options_display.push(new_item);
        });
      },
      deep: true,
    },
    pageCurrent: {
      handler(newv, oldv) {
        this.current_nodes_table = this.nodes_list.slice(
          (newv - 1) * this.pageSize,
          newv * this.pageSize
        );
      },
    },
    nodes_list: {
      handler(newv, oldv) {
        this.current_nodes_table = this.nodes_list.slice(
          (this.pageCurrent - 1) * this.pageSize,
          this.pageCurrent * this.pageSize
        );
      },
      immediate: true,
    },
  },
  created() {
    if (this.$route.params.versionId) {
      sessionStorage.setItem("version_id", this.$route.params.versionId);
    } else if (this.$route.params.cur_id) {
      sessionStorage.setItem("version_id", this.$route.params.cur_id);
    }
    const version_id = sessionStorage.getItem("version_id");
    this.get_all_versions(version_id);
    get_allProjectMember(this.get_pid()).then((res) => {
      let my = {};
      let li = [];
      res.forEach((ele) => {
        let obj = {
          label: ele.nickname,
          value: ele.accountId,
          userAccountId: ele.accountId,
          avatar: ele.avatar,
          deleted: ele.deleted,
        };
        ele.accountId == this.$store.getters.userAccountId
          ? (my = obj)
          : li.push(obj);
      });
      let sort_user_list = [];
      sort_user_list.push(my);
      sort_user_list.push(...li);
      const group = Object.values(this.user_group_list);
      sort_user_list.map((item, index) => {
        this.groupandUser[0].options.push({
          value: item.value,
          label: item.label,
          avatar: item.avatar,
          colorid: item.userAccountId,
          nickname: item.label,
        });
      });
      group.map((item, index) => {
        this.groupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
      });
    });
  },
  mounted() {
    this.display_data.sprint_options = Object.values(this.sprint);
    this.display_data.baseline_options = Object.values(this.baseLine);
    this.ruleForm.baseline_options = Object.values(this.baseLine);
    this.display_data.testPlan_options = Object.values(this.testPlan);
    this.ruleForm.testPlan_options = Object.values(this.testPlan);
    this.get_version_export();
  },
  methods: {
    ...mapActions(["setVersion"]),
    limits_of_authority_disable,
    summaryOnmouserEnter() {
      this.timeOut = setTimeout(() => {
        if (this.activeNames.indexOf("1") == -1 && !this.is_edit) {
          this.activeNames.push("1");
        }
      }, 500);
    },
    summaryOnmouserLeave() {
      clearTimeout(this.timeOut);
      if (this.activeNames.indexOf("1") !== -1 && !this.is_edit) {
        this.activeNames.splice(this.activeNames.indexOf("1"), 1);
      }
    },
    toPubK() {
      window.open(
        "https://ms.ytdevops.com/pubKnowledge/1db7562f-6f55-4d30-907c-390163fe43b8?elementId=01HZ6PFRXX5M5ZMK4HJMQMBA9Z",
        "_blank"
      );
    },
    submit_Excel() {
      let foundObject = this.version_options_display.find(
        (item) => item.versionId === this.$refs.refId.value
      );
      this.excelExportLoading = true;
      let post_data = {
        versionName: foundObject.name.replace(/\([^)]*\)/, ""),
        excelHeadChoicesDto: {
          systemFieldsIdList: [],
          costomFieldsIdList: [],
        },
      };
      this.systemFields_list.forEach((item) => {
        if (this.submit_list.indexOf(item.fieldId) != -1) {
          post_data.excelHeadChoicesDto.systemFieldsIdList.push(item.fieldId);
        }
      });
      this.allFields_list.forEach((item) => {
        if (this.submit_list.indexOf(item.fieldId) != -1) {
          post_data.excelHeadChoicesDto.costomFieldsIdList.push(item.fieldId);
        }
      });
      export_excel_version({
        projectId: this.get_pid(),
        versionId: this.$route.params.cur_id,
        data: post_data,
      })
        .then(async (res) => {
          this.dialogExport = false;
          this.submit_list = [];
          this.all_fields_item = false;
          this.excelExportLoading = false;
          this.$message({
            showClose: true,
            message: this.$t("homeTopBar.asyncTask.message"),
            type: "success",
          });
        })
        .catch((error) => {
          this.excelExportLoading = false;
        });
    },
    judge_all(val) {
      if (val) {
        // 点击全选
        this.checkList.forEach((item) => {
          this.submit_list.push(item.fieldId);
        });
      } else {
        this.submit_list = [];
      }
    },
    get_show_person() {
      let r = [];
      this.notice_person_list.forEach((val) => {
        this.groupandUser[0].options.forEach((d) => {
          if (d.value == val) {
            r.push(d.label);
          }
        });
      });
      return r;
    },
    get_version_export() {
      Promise.all([
        // get_version_select(this.get_pid()),
        getSystemFields(),
        all_fields_get(this.$route.params.projectId),
      ]).then((res) => {
        // 需展示字段
        this.excel_dialog_fields = res[0] || {};
        // 所有系统字段
        this.systemFields_list = res[1] || [];
        // 所有自定义字段
        this.allFields_list = res[2] || [];
        // 筛选系统字段
        this.systemFields_list.forEach((item) => {
          if (
            this.excel_dialog_fields.systemFieldsIdList?.indexOf(
              item.fieldId
            ) != -1
          ) {
            this.checkList.push({ label: item.name, fieldId: item.fieldId });
          }
        });

        // 筛选自定义字段
        this.allFields_list.forEach((item) => {
          if (
            this.excel_dialog_fields.costomFieldsIdList.indexOf(item.fieldId) !=
            -1
          ) {
            this.checkList.push({ label: item.name, fieldId: item.fieldId });
          }
        });
      });
    },
    export_excel() {
      this.dialogExport = true;
    },
    save_notice_person(visible) {
      if (!visible) {
        this.notice_person_list = [];
        let account = [];
        let group = [];
        if (this.display_data.notice_person) {
          this.display_data.notice_person.map((item, index) => {
            if (item.substr(-1) == 0) {
              account.push(item.substr(0, item.length - 1));
            } else {
              group.push(item.substr(0, item.length - 1));
            }
          });
        }
        const params = {
          projectId: this.get_pid(),
          accountIdList: account,
          groupIdList: group,
        };
        this.is_auto_save = true;
        get_group_user_list(params).then((res) => {
          this.notice_person_list = res;

          this.version_options.find((item) => {
            return item.versionId === this.cur_version_id;
          }).name = this.display_data.name;

          this.change_description();

          let data = {
            name: this.display_data.name,
            releaseDateTime: this.display_data.releaseDateTime,
            responsible: this.display_data.responsible,
            releaseTarget: this.display_data.releaseTarget || "",
            remark: this.display_data.remark || "",
            notifyUsers: res,
          };
          edit_version(this.get_pid(), this.cur_version_id, data).then(() => {
            setTimeout(() => {
              this.is_auto_save = false;
            }, 500);
          });
        });
      }
    },
    save_notice_person_2() {
      this.notice_person_list = [];
      let account = [];
      let group = [];
      if (this.display_data.notice_person) {
        this.display_data.notice_person.map((item, index) => {
          if (item.substr(-1) == 0) {
            account.push(item.substr(0, item.length - 1));
          } else {
            group.push(item.substr(0, item.length - 1));
          }
        });
      }
      const params = {
        projectId: this.get_pid(),
        accountIdList: account,
        groupIdList: group,
      };
      this.is_auto_save = true;
      get_group_user_list(params).then((res) => {
        this.notice_person_list = res;

        this.version_options.find((item) => {
          return item.versionId === this.cur_version_id;
        }).name = this.display_data.name;

        this.change_description();

        let data = {
          name: this.display_data.name,
          releaseDateTime: this.display_data.releaseDateTime,
          responsible: this.display_data.responsible,
          releaseTarget: this.display_data.releaseTarget || "",
          remark: this.display_data.remark || "",
          notifyUsers: res,
        };
        edit_version(this.get_pid(), this.cur_version_id, data).then(() => {
          setTimeout(() => {
            this.is_auto_save = false;
          }, 500);
        });
      });
    },
    jump_to_baseline(item) {
      this.$router.push({
        name: "baseline",
        params: {
          cur_id: item.baselineId,
        },
      });
    },
    jump_to_test(item) {
      this.$router.push({
        name: "test",
        params: item,
      });
    },
    jump_to_sprint(item) {
      this.$router.push({
        name: "board",
        params: item,
      });
    },
    // 点击节点key跳转到节点详情
    route_to_node_details(row) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/nodes/key/${row.key}`,
      });
      window.open(href, "_blank");
    },
    // 点击节点key旁的图标跳转到节点在图中的位置
    route_to_node(row) {
      getFile(this.get_pid(), row.fileKey)
        .then((res) => {
          this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
          this.$store.commit("SET_FILE_ID", res.fileId);
          this.$store.commit("SET_FILE_KEY", res.fileKey);
          this.$store.commit("SET_MINDMAP_NAME", res.fileName);
          this.$store.commit("SET_OPEN_STATE", "exist");
          this.$store.commit("SET_FILETYPE_ID", res.fileTypeId);
          //跳转到思维导图
          const { href } = this.$router.resolve({
            path: `/${this.get_pid()}/myMind/${row.fileKey}`,
            query: {
              node_id: row.id,
            },
          });
          window.open(href, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 给节点表格设置样式
    set_cell_style({ columnIndex }) {
      let style = "";
      if (columnIndex == 0) {
        style += "color:rgba(255, 195, 0, 1);";
      } else {
        style += "color:#636363; font-weight: 700";
      }
      return style;
    },
    // 打开添加新版本弹窗
    open_new_version_dialog() {
      this.is_dialog_visible = true;
      this.add_version_dialog_reset();
    },
    // 获得当前所有版本列表
    get_all_versions(version_id) {
      this.loading = true;
      get_all_versions(this.get_pid()).then((res) => {
        const obj = {};
        res.forEach((item) => {
          obj[item.versionId] = item;
        });
        this.$store.commit("setVersion", obj);
        if (!res.length) {
          this.cur_version_id = "";
        }
        this.version_options = res;
        const cur_version = res.find((item) => item.versionId === version_id);
        if (cur_version) {
          this.cur_version_id = cur_version.versionId;
        } else if (this.version_options[0]) {
          this.cur_version_id = this.version_options[0].versionId;
        }
        this.loading = false;
      });
    },
    // 获得某版本详细信息
    get_version_detail() {
      this.loading = true;
      get_version_detail(this.get_pid(), this.cur_version_id).then((res) => {
        this.display_data.name = res.name;
        this.display_data.releaseDateTime = res.releaseDateTime;
        this.display_data.releaseTarget = res.releaseTarget;
        this.display_data.responsible = res.responsible;
        this.display_data.remark = res.remark;
        this.display_data.releaseActualDateTime = res.releaseActualDateTime;
        this.display_data.sprint = [];
        this.notice_person_list = res.notifyUsers;
        let notifyUsers = [];
        res.notifyUsers.forEach((item) => {
          notifyUsers.push(item + "0");
        });
        this.display_data.notice_person = notifyUsers;
        this.is_release = res.status;
        this.display_data.baseline = res.baselines.map(
          (item) => item.baselineId
        );
        this.baseline_old_value = this.display_data.baseline;
        this.display_data.testPlan = res.testPlans.map(
          (item) => item.testPlanId
        );
        this.testPlan_old_value = this.display_data.testPlan;
        this.loading = false;
        this.change_description();
      });
      get_version_nodes(this.get_pid(), this.cur_version_id).then((res) => {
        this.display_data.versionNodeDetail = res;
        res.referenced.forEach((item) => {
          this.display_data.sprint.push(item.id);
        });
        this.sprint_old_value = this.display_data.sprint;
        this.loading = false;
      });
    },
    before_close_drawer() {
      this.get_version_detail();
      this.drawer = false;
    },
    show_drawer(node) {
      const jobAuthorities = this.$store.getters.jobAuthorities;
      if (jobAuthorities.indexOf("NODE_DETAIL_READ") === -1) {
        this.$message({
          message: this.$t("board.message.error"),
          type: "warning",
        });
        return;
      }
      this.drawer_loading = true;
      this.$nextTick(() => {
        get_node_use_node_key(this.get_pid(), node.key).then((res) => {
          this.current_node = res;
          this.drawer = true;
        });
      });
    },
    update_description_content(description) {
      this.current_node.description = description;
    },
    update_current_node(e) {
      this.current_node = e;
    },
    // 删除当前版本
    delete_version() {
      this.$confirm(
        this.$t("version.topBar.deleteVersionForm.info"),
        this.$t("version.topBar.deleteVersionForm.title"),
        {
          confirmButtonClass: "delete_button_class",
          confirmButtonText: this.$t("btn.deleteBtn"),
          cancelButtonText: this.$t("btn.cancelBtn"),
          cancelButtonClass: "delete_cancel_class",
          type: "warning",
        }
      ).then(() => {
        delete_version(this.get_pid(), this.cur_version_id).then((res) => {
          this.is_edit = false;
          this.setVersion(this.get_pid());
          this.get_all_versions();
        });
      });
    },

    // 点击新建版本
    create_version_click() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          create_version(
            this.get_pid(),
            this.ruleForm.name,
            this.ruleForm.baseline,
            this.ruleForm.testPlan,
            this.ruleForm.releaseTarget
          ).then((res) => {
            this.$message({
              type: "success",
              message: this.$t("version.message.addSuccess"),
            });
            this.setVersion(this.get_pid());
            this.get_all_versions(res);
            this.is_dialog_visible = false;
            this.add_version_dialog_reset();
          });
        }
      });
    },
    // 恢复添加版本弹窗
    add_version_dialog_reset() {
      this.ruleForm.name = "";
      this.ruleForm.baseline = [];
      this.ruleForm.testPlan = [];
      this.ruleForm.releaseTarget = "";
    },
    // 编辑按钮点击
    edit_click() {
      this.isExoprt = false;
      this.is_edit = true;
      this.activeNames = ["1"];
    },
    // 退出按钮编辑点击
    exit_edit_click() {
      this.isExoprt = true;
      this.save_notice_person_2();
      this.setVersion(this.get_pid());
      this.is_edit = false;
      this.activeNames = [];
    },
    // 版本内容编辑修改
    edit_version() {
      this.is_auto_save = true;
      this.version_options.find((item) => {
        return item.versionId === this.cur_version_id;
      }).name = this.display_data.name;

      this.change_description();

      let data = {
        name: this.display_data.name,
        releaseDateTime: this.display_data.releaseDateTime,
        responsible: this.display_data.responsible,
        releaseTarget: this.display_data.releaseTarget || "",
        remark: this.display_data.remark || "",
      };
      edit_version(this.get_pid(), this.cur_version_id, data).then(() => {
        setTimeout(() => {
          this.is_auto_save = false;
        }, 500);
      });
    },
    // 获得模糊搜索的节点列表
    get_add_nodes_options(fuzzyKey) {
      if (fuzzyKey == "") return;
      this.addNodesOptionsLoading = true;
      get_nodes_by_fuzzykey(this.get_pid(), fuzzyKey).then((res) => {
        this.addNodesOptionsLoading = false;
        this.display_data.add_nodes_options = res;
      });
    },
    // 添加节点
    add_nodes() {
      this.add_nodes_loading = true;
      add_nodes(
        this.get_pid(),
        this.cur_version_id,
        this.display_data.add_nodes
      )
        .then((res) => {
          get_version_nodes(this.get_pid(), this.cur_version_id)
            .then((res) => {
              this.display_data.versionNodeDetail = res;
              this.display_data.add_nodes = [];
              this.add_nodes_loading = false;
            })
            .catch((err) => {
              this.add_nodes_loading = false;
            });
        })
        .catch((err) => {
          this.add_nodes_loading = false;
        });
    },
    // 删除节点
    delete_nodes(nodeKey, row) {
      if (row.referenced) {
        this.$message({
          message: this.$t("corax.introducedBySprint"),
          type: "warning",
        });
        return;
      }
      this.delete_nodes_loading = true;
      this.delete_nodes_key = nodeKey;
      delete_nodes(this.get_pid(), this.cur_version_id, nodeKey).then((res) => {
        get_version_nodes(this.get_pid(), this.cur_version_id)
          .then((res) => {
            this.display_data.versionNodeDetail = res;
            this.delete_nodes_loading = false;
            this.delete_nodes_key = "";
          })
          .catch((err) => {
            this.delete_nodes_loading = false;
            this.delete_nodes_key = "";
          });
      });
    },
    release_version_api() {
      release_version(this.get_pid(), this.cur_version_id).then(() => {
        this.dialogVisible = false;
        this.$message({
          type: "success",
          message: this.$t("version.message.publishSuccess"),
        });
        let version_id = this.cur_version_id;
        this.get_all_versions(version_id);
        this.get_version_detail();
      });
    },
    // 发布版本
    release_version() {
      if (!this.display_data.responsible) {
        this.$message({
          type: "warning",
          message: this.$t("version.message.publishWarn"),
        });
        return;
      }
      let flag = true;
      this.testPlanName_list.forEach((plan) => {
        if (plan.status !== "COMPLETE") {
          flag = false;
        }
      });
      if (!flag) {
        this.$confirm(this.$t("version.publishForm.info"), {
          customClass: "version_confirm_class",
          title: this.$t("release.ConfirmOperation"),
          type: "warning",
          confirmButtonClass: "version_cancel_button_class",
          cancelButtonClass: "version_confirm_button_class",
          confirmButtonText: this.$t("btn.cancelBtn"),
          cancelButtonText: this.$t("btn.confirmBtn"),
        })
          .then((_) => {})
          .catch((_) => {
            this.nodes_list.forEach((item) => {
              if (item.statusCategory == "DONE") {
                this.dialogVisible = true;
                return;
              }
            });
            if (!this.dialogVisible) {
              this.release_version_api();
            }
          });
      } else {
        this.nodes_list.forEach((item) => {
          if (item.statusCategory != "DONE") {
            this.dialogVisible = true;
            return;
          }
        });

        if (!this.dialogVisible) {
          this.release_version_api();
        }
      }
    },
    // 关联sprint得到焦点
    sprint_select_focus() {
      this.sprint_old_value = this.display_data.sprint;
    },
    // 关联sprint修改
    sprint_select_change() {
      let add_sprint = this.display_data.sprint.filter(
        (item) => !this.sprint_old_value.includes(item)
      )[0];
      let delete_sprint = this.sprint_old_value.filter(
        (item) => !this.display_data.sprint.includes(item)
      )[0];
      if (add_sprint) {
        this.loading = true;
        add_sprints(this.get_pid(), this.cur_version_id, [add_sprint]).then(
          () => {
            get_version_nodes(this.get_pid(), this.cur_version_id).then(
              (res) => {
                this.display_data.versionNodeDetail = res;
                this.loading = false;
              }
            );
          }
        );
      } else if (delete_sprint) {
        this.loading = true;
        delete_sprints(this.get_pid(), this.cur_version_id, delete_sprint).then(
          () => {
            get_version_nodes(this.get_pid(), this.cur_version_id).then(
              (res) => {
                this.display_data.versionNodeDetail = res;
                this.loading = false;
              }
            );
          }
        );
      }
      this.sprint_old_value = this.display_data.sprint;
    },
    // 关联baseline得到焦点
    baseline_select_focus() {
      this.baseline_old_value = this.display_data.baseline;
    },
    // 关联baseline修改
    baseline_select_change() {
      let add_baseline = this.display_data.baseline.filter(
        (item) => !this.baseline_old_value.includes(item)
      )[0];
      let delete_baseline = this.baseline_old_value.filter(
        (item) => !this.display_data.baseline.includes(item)
      )[0];
      if (add_baseline) {
        this.loading = true;
        add_baselines(this.get_pid(), this.cur_version_id, [add_baseline]).then(
          () => {
            get_version_nodes(this.get_pid(), this.cur_version_id).then(
              (res) => {
                this.display_data.versionNodeDetail = res;
                this.loading = false;
              }
            );
          }
        );
      } else if (delete_baseline) {
        this.loading = true;
        delete_baselines(
          this.get_pid(),
          this.cur_version_id,
          delete_baseline
        ).then(() => {
          get_version_nodes(this.get_pid(), this.cur_version_id).then((res) => {
            this.display_data.versionNodeDetail = res;
            this.loading = false;
          });
        });
      }
      this.baseline_old_value = this.display_data.baseline;
    },
    // 关联testPlan得到焦点
    testPlan_select_focus() {
      this.testPlan_old_value = this.display_data.testPlan;
    },
    // 关联testPlan修改
    testPlan_select_change() {
      let add_testPlan = this.display_data.testPlan.filter(
        (item) => !this.testPlan_old_value.includes(item)
      )[0];
      let delete_testPlan = this.testPlan_old_value.filter(
        (item) => !this.display_data.testPlan.includes(item)
      )[0];
      if (add_testPlan) {
        this.loading = true;
        add_testPlans(this.get_pid(), this.cur_version_id, [add_testPlan]).then(
          () => {
            get_version_nodes(this.get_pid(), this.cur_version_id).then(
              (res) => {
                this.display_data.versionNodeDetail = res;
                this.loading = false;
              }
            );
          }
        );
      } else if (delete_testPlan) {
        this.loading = true;
        delete_testPlans(
          this.get_pid(),
          this.cur_version_id,
          delete_testPlan
        ).then(() => {
          get_version_nodes(this.get_pid(), this.cur_version_id).then((res) => {
            this.display_data.versionNodeDetail = res;
            this.loading = false;
          });
        });
      }
      this.testPlan_old_value = this.display_data.testPlan;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleCloseExp() {
      this.dialogExport = false;
    },
    // 对描述进行换行替换处理
    change_description() {
      this.newReleaseTarget = "";
      this.newRemark = "";
      // 对描述进行换行替换处理
      if (this.display_data && this.display_data.releaseTarget) {
        this.newReleaseTarget = this.display_data.releaseTarget.replace(
          /\n/g,
          "<br>"
        );
      }
      if (this.display_data) {
        let arrstr = this.display_data.remark || "";
        this.newRemark = arrstr.replace(/\n/g, "<br>");
      }
    },
    handleChange() {},
  },
};
</script>

<style>
.version_confirm_class {
  top: 18%;
  left: 40%;
  position: absolute;
}
.version_confirm_class .el-message-box__title span {
  font-weight: bold;
  color: black;
}
.version_confirm_button_class {
  background-color: rgb(255, 87, 51) !important;
  color: white !important;
  border: none !important;
}
.version_cancel_button_class {
  background-color: white !important;
  color: #174fa9 !important;
}
</style>
<style lang="scss" scoped>
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.finish-performance {
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 16px;
  color: rgba(128, 128, 128, 1);
  margin: 10px 0 10px 0;
}
.rotate-animate {
  animation: rotate 0.8s linear infinite;
}
// 顶部下拉框和按钮组
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__version-select {
    ::v-deep.el-input__inner {
      width: 260px;
      background-color: transparent;
    }
  }
  &__version-edit {
    .version-name {
      font-size: 18px;
      color: rgba(128, 128, 128, 1);
    }
    ::v-deep.el-input {
      width: 260px;
      .el-input__inner {
        font-size: 16px;
        background-color: transparent;
      }
    }
  }
  &__btns-edit {
    display: flex;
    align-items: center;
  }
}
.header__btns {
  display: flex;
  align-items: center;
  & > div {
    margin-left: 10px;
  }
}

// 版本信息区域
.version-info {
  padding: 17px 23px 0px;
  border-radius: 8px;
  background-color: #fff;
  font-size: 14px;
  line-height: 20px;
  color: rgba(128, 128, 128, 1);
  .release-time-and-person {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .release-time {
      display: flex;
      align-items: center;
      .title {
        flex: none;
        text-align: start;
        font-weight: bold;
        min-width: 120px;
        display: inline-block;
      }
      ::v-deep.el-date-editor {
        width: 185px;
      }
    }
    .release-person {
      display: flex;
      align-items: center;
      text-align: left;
    }
    .notice-person {
      display: flex;
      align-items: center;
      text-align: left;
    }
  }
  .baseline-and-test {
    display: flex;
    align-items: baseline;
    margin-bottom: 24px;
    flex-wrap: wrap;
    line-height: 30px;
    .baseline {
      text-align: left;
      align-items: baseline;
      display: flex;
      .title {
        flex: none;
        text-align: start;
        font-weight: bold;
      }
      ::v-deep.el-input {
        width: 200px;
      }
    }
    .test_label {
      display: flex;
      align-items: baseline;
      text-align: left;
    }
    .sprint {
      display: flex;
      align-items: baseline;
      overflow: hidden;
      text-align: left;
    }
    .bgc-blue {
      color: rgba(42, 130, 228, 1);
      cursor: pointer;
    }
  }
  .version-release-plan {
    display: flex;
    text-align: start;
    margin-bottom: 24px;
    word-break: break-all;
    .title {
      flex: none;
      font-weight: bold;
    }
  }
  .remark {
    display: flex;
    text-align: start;
    word-break: break-all;
    .title {
      flex: none;
      font-weight: bold;
    }
  }
}

.version-nodes {
  padding: 8px 0;
  margin-top: 10px;
  border-radius: 8px;
  background-color: #fff;
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 1px;
    // height: 70vh;
  }
  ::v-deep .el-table::before {
    height: 0;
  }
  ::v-deep .el-table__row {
    td:nth-child(2) {
      > div {
        font-weight: bold;
      }
    }
  }
}

.release {
  position: fixed;
  margin-left: 225px;
  z-index: 999;
  ::v-deep .el-button {
    height: 32px;
  }
}

::v-deep .el-select {
  .el-select__tags-text {
    display: inline-block;
    max-width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .el-tag__close {
    margin-top: -12px;
  }
}
.el-select-dropdown.is-multiple .el-select-dropdown__item {
  padding-right: 28px;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  right: 10px;
}

.add_nodes {
  text-align: end;
  ::v-deep .el-select {
    .el-tag:first-child {
      display: flex;
      align-items: center;
      .el-select__tags-text {
        display: inline-block;
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }
  }
  .title {
    font-size: 14px;
    color: rgba(144, 147, 153, 1);
    margin-right: 5px;
  }
  ::v-deep .el-select-dropdown {
    // 解决下拉框错位
    position: absolute !important;
    // top: -277px !important;
    left: 0 !important;
    max-width: 400px;
    .popper__arrow {
      display: none;
    }
  }
  ::v-deep .el-input {
    width: 318px;
    margin-right: 12px;
  }
}

.version-add {
  font-size: 18px;
  width: 100%;
}

::v-deep.el-select-dropdown__item.version-add-option::after {
  content: "";
}

// 按钮的样式
// ::v-deep .el-button {
//   font-size: 16px;
//   padding: 6px 10px;
// }

// 滚动条不显示横轴
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  margin-bottom: 0 !important;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>

<style lang="scss">
.select_own .el-select-dropdown__list {
  padding: 0;
  margin: 0;
  li:nth-child(1) {
    background-color: rgba(77, 160, 255, 0.35);
    border-radius: 6px 6px 0px 0px;
  }
}
.excel_export_dialog {
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: hidden scroll;
  }

  .el-checkbox {
    padding-bottom: 16px;
    display: flex;
    color: rgba(128, 128, 128, 1);

    .el-checkbox__label {
      font-size: 16px;
      font-weight: 400;
      // color: rgba(56, 56, 56, 1);
      text-align: left;
    }
  }
}
</style>
<style lang="scss" scoped>
.collapse_item {
  margin-bottom: 10px;
  margin-top: 17px;
  border-radius: 8px;
}
.collapse-title {
  display: flex;
  align-items: center;

  height: 27px;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-weight: 500;
  left: 40px;
  order: 1;
}
.el-collapse-item__header {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  order: -1;
  background: rgba(229, 229, 229, 1);
  border-radius: 8px !important;
}
::v-deep .el-collapse-item__arrow {
  width: 20px;
  height: 20px;
  background: rgba(229, 229, 229, 1);
  margin-left: 5px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-collapse-item__header {
  border-radius: 8px;
}
.select-icon-height-auto-class {
  .el-select-dropdown__item {
    display: flex;
  }
  .el-select-dropdown__item.selected::after {
    right: 10px !important;
  }
}
.aboveTable {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.aboveTable > :only-child {
  margin-left: auto; /* 使唯一的子元素靠右 */
}

::v-deep .el-pagination {
  .btn-prev {
    background: transparent !important;
  }
  .btn-next {
    background: transparent !important;
  }
  li {
    background: transparent !important;
  }
}
</style>
